<!-- eslint-disable vue/valid-template-root -->
<template>
  <PostLoading v-if="pageLoading" />
  <PostError
    v-else-if="pageError"
    :custom-header="false"
    :text="pageError"
    @buttonClick="reloadPage"
    button-title="刷新一下"
    status="fail"
  />
  <PostError
    v-else-if="!pageError && !historyList.length"
    :custom-header="false"
    @buttonClick="reloadPage"
    text="暂无内容"
    button-title="刷新一下"
    status="empty"
  />
  <div
    v-else
    class="page-lottery-history"
  >
    <!-- <PostHeader
    :menu-status="false"
  >
    中奖记录
  </PostHeader> -->
    <ScrollView
      :loading="loadingStatus"
      :error="pageError"
      :no-more="pageNow >= pageTotal"
      :bottom-distance="0"
      :no-more-text="historyList.length > 5 ? '-THE END-' : ''"
      @reachBottom="getPageData"
    >
      <li
        v-for="item in historyList"
        :key="item.id"
      >
        <h2 class="page-lottery-header">
          恭喜你，中奖了
          <span
            v-if="item.pageFromId"
            @click="jumpToLotteryFrom(item.pageFromId)"
            class="page-lottery-header__detail-icon"
          />
        </h2>
        <div class="page-lottery-content">
          <div class="page-lottery-content__img">
            <img :src="item.info.img">
          </div>
          <div class="page-lottery-content__text">
            <h3 class="page-lottery-content__gift-name">
              {{ item.info.name }}
            </h3>
            <div class="page-lottery-content__tips">
              <div v-if="item.type===0">
                你在“{{ activityName }}”抽奖活动中奖了~<br>奖品将会发放至你的游戏账户，请注意查收哦~
              </div>
              <div v-else-if="item.type===2">
                你在“{{ activityName }}”抽奖活动中奖了~<br>请尽快兑换Codekey的相应奖励~
                <input
                  :class="`cdkey-box-${item.id}`"
                  :value="item.info.cdkey"
                  class="cdkey-box"
                  readonly
                >
              </div>
              <div v-else-if="item.type===3">
                你在“{{ activityName }}”抽奖活动中奖了~<br>Q币将发放至指定QQ号中，请注意查收哦~
              </div>
              <div v-else-if="item.type===1">
                <span v-if="!isReadGoodLost(item)">你在“{{ activityName }}”抽奖活动中奖了~<br>请在活动结束前完善信息，活动后不支持填写以及修改。</span>
                <span v-else>未在活动前完善收货信息，礼品失效了</span>
              </div>

              <div
                v-if="item.type===1"
                class="page-lottery-content__address"
              >
                <p>姓名：{{ allRecordAddress[item.info.id] && allRecordAddress[item.info.id]['name'] }}</p>
                <p>电话：{{ allRecordAddress[item.info.id] && allRecordAddress[item.info.id]['mobile'] }}</p>
                <p>地址：{{ allRecordAddress[item.info.id] && allRecordAddress[item.info.id]['address'] }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="page-lottery-footer">
          <span class="page-lottery-footer__time">{{ formatTime(item.time) }}</span>
          <div
            v-if="item.type===2"
            @click="copyCdkey(item.id)"
            class="page-lottery-footer__copy"
          >
            复制cdkey
          </div>
          <div
            v-if="item.type===1 && !isActivityEnd"
            @click="openAddressForm(item)"
            class="page-lottery-footer__address"
          >
            修改收货信息
          </div>
        </div>
      </li>
    </ScrollView>

    <!-- 提交成功 -->
    <Dialog
      v-model="addressSubmitSuccess"
    >
      <h2 class="lottery-record-dialog__title">
        提交成功
      </h2>
      <span
        @click="addressSubmitSuccess=false"
        class="lottery-record-dialog__close"
      />
      <p class="lottery-record-dialog__tips">
        信息提交成功，小编会尽快寄出礼品的，请耐心等待~
      </p>
      <lottery-button-base
        @click.native="addressSubmitSuccess=false"
        type="primary"
      >
        我知道了
      </lottery-button-base>
    </Dialog>

    <!-- 地址表单 -->
    <Dialog
      v-model="addressFormStatus"
    >
      <h2 class="lottery-record-dialog__title">
        请填写领取信息
      </h2>
      <span
        @click="addressFormStatus=false"
        class="lottery-record-dialog__close"
      />
      <input
        v-model.trim="editAddressInfo.name"
        class="lottery-record-dialog__input first"
        type="text"
        placeholder="请输入姓名"
        maxlength="30"
      >
      <input
        v-model.trim="editAddressInfo.mobile"
        class="lottery-record-dialog__input"
        type="number"
        placeholder="请填写手机号码"
        maxlength="11"
      >
      <input
        v-model.trim="editAddressInfo.address"
        class="lottery-record-dialog__input"
        type="text"
        placeholder="请输入省市区街道地址"
        maxlength="500"
      >
      <lottery-button-base
        @click.native="submitAddressChange"
        type="primary"
      >
        修改
      </lottery-button-base>
    </Dialog>
  </div>
</template>

<script>
import PostLoading from '@/component/post-loading/index.vue';
import PostError from '@/component/post-error/index.vue';
// import PostHeader from '@/component/post-header/index.vue';
import LotteryButtonBase from '@/component/game-lottery/component/lottery-button-base/index.vue';
import ScrollView from '@/ui/scroll-view/index.vue';
import Dialog from '@/ui/dialog/index.vue';
import lotteryApi from '@/api/lottery';
import reportApi from '@/api/report';
import { openUrl } from '@/jsbridge';
import iconGiftDefault from './img/icon-gift-default.png';
import qbDefaultIcon from './img/icon-letter-gift-qbi.png';

export default {
  name: 'LotteryRecord',
  components: {
    PostLoading,
    PostError,
    // PostHeader,
    ScrollView,
    Dialog,
    LotteryButtonBase,
  },
  data() {
    return {
      pageLoading: true,
      pageError: false,
      pageNow: 0,
      pageTotal: 1,
      historyList: [],
      loadingStatus: false,
      flowInfo: [],
      realGiftInfo: {},
      activityId: '',
      activityName: '',
      activityInfo: {},
      allRecordAddress: {},

      amsLock: false,
      addressSubmitSuccess: false,
      addressFormStatus: false,
      editAddressInfo: {
        packageId: '',
        packageName: '',
        name: '',
        mobile: '',
        address: '',
      },
    };
  },
  computed: {
    isActivityEnd() {
      return new Date(this.activityInfo.endTime).valueOf() < new Date().valueOf();
    },
  },
  mounted() {
    this.pageLoading = true;
    this.getPageData();
  },
  methods: {
    reloadPage() {
      window.location.reload();
    },
    getActivityConfigInfo() {
      return lotteryApi.getLotteryConfig(this.activityId).then((lotteryConfig) => {
        this.flowInfo = lotteryConfig.flowInfo || [];
        this.activityInfo = lotteryConfig.activityInfo;
        const attributes = lotteryConfig?.htmlInfo[0] && lotteryConfig.htmlInfo[0].attributes;
        const htmlInfo = attributes || {};
        const customGiftItemInfo = {};
        (htmlInfo.giftItemInfo || []).forEach((v) => {
          customGiftItemInfo[v.giftItemId.value] = {
            id: v.giftItemId.value,
            img: v.giftItemImg.value,
            name: v.giftItemName.value,
          };
        });
        const realGiftInfo = {};
        (lotteryConfig.packageInfo[0].arrPackageInfo || []).forEach((v) => {
          const vImg = customGiftItemInfo[v.iPackageId] && customGiftItemInfo[v.iPackageId].img;
          realGiftInfo[v.iPackageId] = {
            id: v.iPackageId,
            name: (customGiftItemInfo[v.iPackageId] && customGiftItemInfo[v.iPackageId].name) || v.sPackageName,
            img: vImg || (Number(v.iPackageType) === 1 ? qbDefaultIcon : iconGiftDefault),
            isQB: Boolean(Number(v.iPackageType)),
          };
        });
        this.activityName = lotteryConfig.activityInfo.activityName;
        this.realGiftInfo = realGiftInfo;
        return true;
      });
    },
    getLotteryHistory() {
      const flowInfo = lotteryApi.getFlowIdByName('查询个人获奖记录', this.flowInfo);
      if (flowInfo) {
        return lotteryApi.getLotteryHistory({
          activityId: flowInfo.activityId,
          flowId: flowInfo.flowId,
          page: this.pageNow + 1,
          gameCode: this.activityInfo.gameServiceType,
        });
      }
      return true;
    },
    getPageData() {
      this.activityId = this.$route.query.act_id;

      if (!this.activityId) {
        this.pageLoading = false;
        return;
      }
      if (this.pageNow + 1 > this.pageTotal || this.loadingStatus) {
        this.pageLoading = false;
        return;
      }
      this.loadingStatus = true;
      let flowInfoStatus = [];
      if (!this.flowInfo.length) {
        // lotteryApi.getFlowIdByName('查询个人获奖记录', lotteryConfig.flowInfo)
        flowInfoStatus = this.getActivityConfigInfo();
      }
      Promise.all([
        lotteryApi.checkMiloLoadAndLogin(),
        Promise.resolve(flowInfoStatus),
      ]).then(([loginResult]) => {
        if (loginResult === 'no_check_env') {
          return Promise.reject(new Error('check login fail.'));
        }
        return null;
      })
        .then(() => Promise.all([
          this.getLotteryHistory(),
          this.getRocordAddress(),
        ]))
        .then((result) => {
          const historyList = result[0];
          this.pageTotal = Number(historyList.pageTotal);
          this.pageNow = Number(historyList.pageNow);
          this.historyList = [
            ...this.historyList,
            ...(historyList.list || []).map((v) => {
              if (!v.sPackageName) {
                return { type: 4 };
              }
              const lotteryType = v.sItemType ? Number(v.sItemType) : 4;
              const resultItem = {
                type: 4,
              };
              // 礼包信息
              if (!v.iPackageId) {
                resultItem.type = 4;
              } else {
                resultItem.info = this.realGiftInfo[v.iPackageId] || {};
                resultItem.info.cdkey = v.sCdkey;
                resultItem.sRoleName = v.sRoleName;
                resultItem.sRolePartition = v.sRolePartition;
                resultItem.time = v.dtGetPackageTime;
                resultItem.id = v.id;
                resultItem.pageFromId = v.sExtend4;
              }
              // type转换 0-虚拟物品，1-实际物品，2-cdkey，3-q币，4-未中奖
              const lotteryTypeMap = {
                '-100': 1,
                '-103': 2,
                '-101': 3,
              };
              if (lotteryType < 0) {
                resultItem.type = lotteryTypeMap[lotteryType];
              } else if (lotteryType >= 0) {
                resultItem.type = 0;
              }

              return resultItem;
            }).filter(v => v.type !== 4),
          ];

        // 临时调试
        // this.historyList[0].type = 1;
        // this.historyList[0].info.cdkey = 'DW321HU08321NULFW9823179F';
        })
        .catch((e) => {
          this.$logger.err('getLotteryHistory getRocordAddress', e);
          this.pageError = '内容加载失败，换个姿势刷新试试';
        })
        .finally(() => {
          this.pageLoading = false;
          this.loadingStatus = false;
        });
    },
    getRocordAddress() {
      const flowInfo = lotteryApi.getFlowIdByName('填写实物中奖个人信息(支持显示填写的历史记录)', this.flowInfo);
      if (flowInfo) {
        return lotteryApi.getGiftAddress({
          activityId: flowInfo.activityId,
          flowId: flowInfo.flowId,
        }).then((result) => {
          if (Number(result.iRet) !== 0) {
            this.$toast(result.sMsg);
            return false;
          }
          const recordResult = result.jData;
          (recordResult.arrPackageInfo || []).forEach((v) => {
            this.allRecordAddress[v.iPackageId] = {
              name: recordResult.sName,
              mobile: recordResult.sMobile,
              address: recordResult.sAddress,
            };
          });

          return true;
        })
          .catch((e) => {
            this.$toast(e.message || '服务器繁忙，请稍后重试');
            return false;
          });
      }
      return false;
    },
    formatTime(time) {
      // eslint-disable-next-line no-unused-vars
      const [begin, year, month, day, hours, minutes, second] = time.match(/(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/);
      const nowTime = new Date();
      const oldTime = new Date(year, month - 1, day, hours, minutes, second);
      const timeRange = nowTime.valueOf() - oldTime.valueOf();
      const currYear = nowTime.getFullYear();
      const currYearDate = `${currYear}-1-1`;
      const yearBeginTime = new Date(currYearDate).valueOf();
      let result = '';
      if (timeRange <= 60 * 1000) {
        result = '刚刚';
      } else if (timeRange < 60 * 60 * 1000) {
        result = `${parseInt(timeRange / 60 / 1000, 10)}分钟前`;
      } else if (timeRange < 24 * 60 * 60 * 1000) {
        result = `${parseInt(timeRange / 60 / 60 / 1000, 10)}小时前`;
      } else if (timeRange < 30 * 24 * 60 * 60 * 1000) {
        result = `${parseInt(timeRange / 60 / 60 / 24 / 1000, 10)}天前`;
      } else if (oldTime.valueOf() > yearBeginTime) {
        result = `${month}-${day}`;
      } else {
        result = `${year}-${month}-${day}`;
      }
      return result;
    },
    copyCdkey(id) {
      const selection = window.getSelection();
      const range = document.createRange();
      const dom = this.$el.querySelector(`.cdkey-box-${id}`);
      range.selectNodeContents(dom);
      selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand('copy', false, dom.select());
      this.$toast('复制成功！');
    },
    openAddressForm(item) {
      this.editAddressInfo = {
        packageId: item.info.id,
        packageName: item.info.name,
        ...this.allRecordAddress[item.info.id],
      };
      this.addressFormStatus = true;
    },
    submitAddressChange() {
      // ams地址填写调用
      const hasInputInfo = this.editAddressInfo;
      if (!hasInputInfo.name) {
        this.$toast('姓名不能为空');
        return;
      }
      if (window.milo.getByteLength(hasInputInfo.name) > 30) {
        this.$toast('姓名长度不能超过30个字节');
        return;
      }
      if (!hasInputInfo.mobile || !/^1\d{10}$/.test(hasInputInfo.mobile)) {
        this.$toast('请输入正确格式的手机号码');
        return;
      }
      if (!hasInputInfo.address) {
        this.$toast('详细地址不能为空');
        return;
      }
      if (window.milo.getByteLength(hasInputInfo.address) > 500) {
        this.$toast('详细地址不能超过500个字节');
        return;
      }

      this.amsLock = true;
      const flowInfo = lotteryApi.getFlowIdByName('填写实物中奖个人信息(支持显示填写的历史记录)', this.flowInfo);
      if (flowInfo) {
        lotteryApi.saveGiftAddress({
          activityId: flowInfo.activityId,
          flowId: flowInfo.flowId,
          packageId: hasInputInfo.packageId,
          packageInfo: `${hasInputInfo.packageId}|${hasInputInfo.packageName}`,
          name: hasInputInfo.name,
          mobile: hasInputInfo.mobile,
          address: hasInputInfo.address,
        }).then(() => {
          this.addressFormStatus = false;
          this.addressSubmitSuccess = true;
          this.getRocordAddress();
        })
          .catch((e) => {
            this.$toast(e.message || '服务器繁忙，请稍后重试');
          })
          .finally(() => {
            this.amsLock = false;
          });
      }
    },
    jumpToLotteryFrom(pageFromId) {
      openUrl({
        url: `${window.location.origin}/community/post-detail?postId=${pageFromId}&_gcwv=2`,
        target: 1,
      });
    },
    isReadGoodLost(item) {
      const packageId = item.info.id;
      const addressInfo = this.allRecordAddress[packageId];
      return this.isActivityEnd && (!addressInfo.name && !addressInfo.mobile && !addressInfo.address);
    },
    // 数据上报
    reportData(params, desc) {
      return reportApi(params, desc);
    },
  },
};
</script>

<style lang="scss" src="./scss/index.scss"></style>
